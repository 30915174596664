<section class="form_backBtn_section">
  <a class="form_backPageBtn" routerLink="/dashboard">Back to Dashboard</a>
</section>
<app-progress-smr-test [countOfPages]="11" [currentPage]="11"></app-progress-smr-test>
<section class="form_section">
  <div class="form_container">
    <h4 class="form_header">Your biggest take away</h4>
    <p class="form_description">
      Now, please leave yourself a comment, thought, or action that you have taken away from todays session?
      <br><br>
      What will your next steps or actions be?
    </p>
    <form [formGroup]="form">
      <div class="feedback_message">
        <p class="form_sectionHeader">Notes</p>
        <textarea
          formControlName="note"
          class="form_messageInput"
          name="feedback_text"
          id=""
          cols="30"
          rows="7"
          placeholder="Enter your notes"
        ></textarea>
      </div>
    </form>
    <div *ngIf="form.controls.note.touched &&  form.controls.note.errors" class="error-block">
      <i class="error-icon" aria-hidden="true"></i>
      <span>Please leave your note to yourself in the future. The field accepts at least 30 characters, with a maximum of 500 characters.</span><br>
    </div>
    <div>
    </div>
    <div class="clearFix"></div>
    <div class="formBtn_container">
      <button (click)="submitFeedback()" class="form_NextBtn">Finish</button>
    </div>
  </div>
</section>
