import {Injectable} from '@angular/core';
import {firstValueFrom, Observable, of} from "rxjs";
import {HttpClientService} from "../../shared/services/http-client.service";
import {CreateTeamRequest} from "../models/CreateTeamRequest";
import {UpdateTeamRequest} from "../models/update-team-request";
import {Teams} from "../../dashboard/models/teams";
import {Team} from "../../dashboard/models/team";

@Injectable({
  providedIn: 'root'
})
export class TeamService {
  private teams: Teams = null;

  constructor(private httpService: HttpClientService) {
  }

  createTeam(request: CreateTeamRequest): Observable<any> {
    this.resetTeams();
    return this.httpService.post(`api/team`, request);
  }

  canCreateNewTeam(): Observable<any> {
    return this.httpService.get(`api/team/can-create`);
  }

  updateTeam(request: UpdateTeamRequest): Observable<any> {
    return this.httpService.post(`api/team/update`, request);
  }

  getUserTeams(): Observable<Teams> {
    if (this.teams == null) {
      return this.httpService.get<Teams>(`api/team`);
    } else {
      return of(this.teams);
    }
  }

  resetTeams() {
    this.teams = null;
  }

}
