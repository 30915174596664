import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {OnBoardingStatusService} from "../services/onboarding-status.service";
import {OnboardingStatus} from "../../onboarding/models/onboarding-status";
import {TokenStorageService} from "../services/token.service";

@Injectable({
  providedIn: 'root'
})
export class OnBeforeOnboardingGuard implements CanActivate {
  constructor(private onboardingStatusService: OnBoardingStatusService, private tokenService: TokenStorageService, private router: Router) {
  }

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    let onboardingStatus = await this.onboardingStatusService.getStatus();
    let userRole = this.tokenService.getUserRoles();
    let onboardingInfoPrefix = `/onboarding/info/${userRole}`;
    const isOnboardingInfoUrl = state.url.startsWith(onboardingInfoPrefix);
    if (onboardingStatus == OnboardingStatus.Info) {
      if (isOnboardingInfoUrl) {
        return true;
      } else {
        await this.router.navigate([onboardingInfoPrefix]);
        return false;
      }
    }

    let onboardingRolePrefix = `/onboarding`;
    const isOnboardingRouteUrl = state.url.endsWith(onboardingRolePrefix);
    if (onboardingStatus == OnboardingStatus.Role) {
      if (isOnboardingRouteUrl) {
        return true;
      } else {
        await this.router.navigate([onboardingRolePrefix]);
        return false;
      }
    }
    return true;
  }

}
