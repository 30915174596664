import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClientService} from 'src/app/shared/services/http-client.service';
import {ShortDropdownListItemModel} from "../../../shared/models/short-dropdown-list-item";
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ISmrTestFeedbackCreateRequest} from "../contracts/feedbackCreateRequest";
import {ICreateFeedbackModel} from "../contracts/createFeedbackModel";
import {TestService} from "../test.service";
import {DisplayRoleHelper} from "../../../shared/helpers/display-role-helper";
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-smr-test-feedback',
  templateUrl: './smr-test-feedback.component.html',
  styleUrls: ['./smr-test-feedback.component.scss'],
})
export class SmrTestFeedbackComponent implements OnInit {
  public allUsers: ShortDropdownListItemModel[];
  public users: ShortDropdownListItemModel[];
  public formArray: FormArray;
  private testId: string;
  public toResultUrl: string;
  protected readonly FormGroup = FormGroup;
  public noteUrl;
  public openNoteProposal: any;

  constructor(
    private httpClientService: HttpClientService,
    private testService: TestService,
    private router: Router,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private titleService: Title
  ) {
    this.formArray = this.fb.array([this.createForm()]);
  }

  get isAvailableAddMoreFeedback(): boolean {
    return this?.users?.length >= 1;
  }

  async ngOnInit() {
    this.testId = this.activatedRoute.snapshot.paramMap.get('testId');
    this.noteUrl = `/smr/${this.testId}/note`;
    this.toResultUrl = this.testService.SelectedTeamId ? `/smr/result/${this.testService.SelectedTeamId}/score` : `/smr/result/score`;
    this.httpClientService
      .get<any>(`api/users/test/${this.testId}`)
      .subscribe((response) => {
        this.allUsers = response.users.map(x => {
          return {name: `${x.fullName}  (${x.title ? x.title : DisplayRoleHelper.getDisplayRole(x.role)})`, id: x.id}
        });
        this.users = this.allUsers;
        if (this.users.length == 0) {
          this.router.navigate([`smr/${this.testId}/note`]);
          return;
        }
      });
    this.titleService.setTitle('SMR feedback');
  }

  getForm(index: number): FormGroup {
    return this.formArray.at(index) as FormGroup;
  }

  createForm(): FormGroup {
    return new FormGroup({
      selectedUserId: new FormControl(null, [Validators.required]),
      feedback: new FormControl('', [Validators.required, Validators.minLength(30), Validators.maxLength(500)]),
    })
  }

  addMoreFeedbackToArray = () => {
    if (!this.isFormArrayValid()) {
      this.formArray.markAllAsTouched();
      return;
    }
    this.formArray.push(this.createForm());
    this.cdr.detectChanges();
  };

  isFormArrayValid(): boolean {
    if (this.formArray.invalid) {
      return false;
    }

    for (const formGroup of this.formArray.controls) {
      if (formGroup.invalid) {
        return false;
      }
    }

    return true;
  }

  submitFeedback = () => {
    const requestObject: ISmrTestFeedbackCreateRequest =
      {} as ISmrTestFeedbackCreateRequest;
    requestObject.createdTestId = this.testId;
    requestObject.createFeedbackModels = [];

    this.formArray.controls.map((formGroup) => {

      let newObject: ICreateFeedbackModel = {} as ICreateFeedbackModel;
      newObject.userId = formGroup.get('selectedUserId')?.value;
      newObject.feedbackText = formGroup.get('feedback')?.value;
      requestObject.createFeedbackModels.push(newObject);
    });

    this.httpClientService
      .post('api/smrtest/feedback', requestObject)
      .subscribe(async (response) => {
        this.openNoteProposal = true;
        return;
      });
  };

  selectUser(selected: any) {
    let usersId = this.formArray.controls.map(formGroup => {
      return formGroup.get('selectedUserId')?.value;
    });
    this.users = this.allUsers.filter(x => usersId.indexOf(x.id) < 0);
    this.cdr.detectChanges();
  }

  onCloseHelp() {
    this.openNoteProposal = false;
    this.router.navigate([this.toResultUrl]);
  }
}
