import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {UserTeam} from "../../dashboard/models/user-team";
import {firstValueFrom} from "rxjs";
import {TeamService} from "../../team/services/team.service";
import {SmrService} from "./smr.service";
import {filter} from "rxjs/operators";
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-smrResults',
  templateUrl: './smrResults.component.html',
  styleUrls: ['./smrResults.component.scss'],
})
export class SmrResultsComponent implements OnInit {
  public selectedTeam: UserTeam;
  tabName: string = null;
  readonly PERSONAL_TAB = 'personal';
  readonly TEAM_TAB = 'team';
  public teams: UserTeam[];
  public passedTestTeamMemberCount: number;

  constructor(
    private smrService: SmrService,
    public router: Router,
    private activateRouter: ActivatedRoute,
    private teamService: TeamService,
    private titleService: Title
  ) {
    this.tabName = this.PERSONAL_TAB;
  }

  async ngOnInit() {
    this.teams = (await firstValueFrom(this.teamService.getUserTeams())).teams;
    this.teams.sort((a, b) => a.teamName.localeCompare(b.teamName));
    let selectedTeam = this.activateRouter.snapshot.paramMap.get("teamId");
    if (selectedTeam != null) {
      this.selectedTeam = this.teams.filter(x => x.teamId.toString() == selectedTeam)[0];
      this.tabName = this.TEAM_TAB;
      this.titleService.setTitle('SMR Personal result | TEMA');
    } else {
      this.selectedTeam = this.teams[0];
      this.titleService.setTitle('SMR Team result | TEMA');
    }
    this.subscribeOnScoreResult();
    await this.loadScoreResults();
  }

  async activatePersonalTab() {
    this.tabName = this.PERSONAL_TAB;
    await this.router.navigate(['smr/result/score']);
  }

  async callScoreTab(queryString ?: string) {
    let currentTeam = this.activateRouter.snapshot.paramMap.get('teamId');
    let url = '';
    if (currentTeam) {
      url = `/smr/result/${currentTeam}/score`;
    } else {
      url = `smr/result/score`;
    }

    if (queryString) {
      url = url + queryString;
    }

    await this.router.navigateByUrl(url);
  }

  async moveToPersonalNotes() {
    let urlRoute: string = `/smr/result/notes`;
    await this.router.navigateByUrl(urlRoute);
  }

  async changeTimePeriod(timePeriod: number) {
    this.changeQueryParameters({DateRangeType: timePeriod});
  }

  private async loadScoreResults() {
    let currentTeam = this.activateRouter.snapshot.paramMap.get('teamId');
    let dateRange: string = this.activateRouter.snapshot.queryParamMap.get('DateRange') ?? "1";
    if (currentTeam) {
      await this.smrService.loadNewScoreResultData(dateRange, currentTeam);
    } else {
      await this.smrService.loadNewScoreResultData(dateRange, currentTeam);
    }
  }

  private changeQueryParameters(queryParams: Params) {

    this.router.navigate(
      [],
      {
        relativeTo: this.activateRouter,
        queryParams: queryParams,
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });
  }

  timePeriodNameDefinitor() {
    if (this.router.url.includes("DateRangeType=2")) {
      return "3 Months";
    }
    if (this.router.url.includes("DateRangeType=3")) {
      return "6 Months";
    } else {
      return "Month"
    }
  }

  openHelp: any;

  shouldShownTimePeriodDropwDown() {
    return !(this.router.url.includes('note') || this.router.url.includes('feedback'));
  }

  bookACall() {
    this.openHelp = true;
  }

  isNoteActive() {
    return this.activateRouter.firstChild?.snapshot.url[0].path == 'notes'
  }

  isScoreActive() {
    return this.activateRouter.firstChild?.snapshot.url[0].path == 'score';
  }

  onCloseHelp() {
    this.openHelp = false;
  }

  async activateTeamTab(team: any) {
    this.tabName = this.TEAM_TAB;
    this.selectedTeam = team;
    let url = `/smr/result/${team.teamId}/score`;
    await this.router.navigateByUrl(url);
    await this.loadScoreResults();
  }

  moveToFeedbackNotes() {
    let urlRoute: string = `/smr/result/${this.selectedTeam.teamId}/feedback`;
    this.router.navigateByUrl(urlRoute);
  }

  isFeedBackActive() {
    return this.router.url.indexOf('feedback') > 0;
  }

  private subscribeOnScoreResult() {
    this.smrService.scoreResults$.pipe(filter(x=>!!x)).subscribe(x=>{
      this.passedTestTeamMemberCount = x.passedTestTeamMemberCount;
    })
  }
}

