<div class="content_wrapper">
  <div class="smr_results">
    <a class="goback_link" routerLink="/dashboard"><i class="arrow" ari-hidden="true"></i><span>Back to Dashboard</span></a>
    <div class="smr_results__title">
      <h1 class="title">Success Metrics Rating results</h1>
      <div class="btns">
        <button class="more_info">
          <span class="mobile">Info</span>
          <i class="moreinfo_icon" aria-hidden="true"></i>
        </button>
        <button class="booking_call" (click)="bookACall()">
          <i class="call_icon" aria-hidden="true"></i>
          <span>Need help?</span>
        </button>
      </div>
    </div>
    <div class="navigation">
      <ul class="nav">
        <li class="nav-item">
          <a class="nav-link"
             [ngClass]="{'active': tabName == PERSONAL_TAB}"
             id="personal"
             (click)="activatePersonalTab()"
             aria-current="page">Personal</a>
        </li>
        <li class="nav-item dropdown" *ngIf="teams?.length > 0">
          <div *ngIf="selectedTeam" class="nav-link dropdown-toggle"
               [ngClass]="{'active': tabName == TEAM_TAB, 'disabled': teams.length == 0}" role="button"
               data-bs-toggle="dropdown" aria-expanded="false">
            {{selectedTeam.teamName}}
          </div>
          <ul class="dropdown-menu">
            <li *ngFor="let team of teams; let i = index;">
              <div class="dropdown-item" (click)="activateTeamTab(team)">
                <p>{{team.teamName}}</p>
                <span>{{team.members}} member<span *ngIf="team.members > 1">s</span>
                  </span>
                <div class="separator_line" *ngIf="i !== teams.length - 1"></div>
              </div>
            </li>
          </ul>
        </li>
      </ul>
      <p class="frequancy">Your current test frequency is 1 month.</p>
    </div>
    <div class="smr_tabs">
      <div class="smr_tabs__header">
        <ul class="nav" id="myTab" role="tablist">
          <li class="nav-item" role="presentation">
            <button
              (click)="callScoreTab()"
              class="nav-link"
              [ngClass]="{ 'active' :isScoreActive()}"
              data-bs-toggle="tab"
              data-bs-target="#score-tab"
            >
              Score
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button *ngIf="tabName == PERSONAL_TAB"
                    (click)="moveToPersonalNotes()"
                    class="nav-link"
                    [ngClass]="{ 'active' :isNoteActive()}"
                    data-bs-toggle="tab"
                    data-bs-target="#note-tab"
            >
              Notes
            </button>

            <button *ngIf="tabName == TEAM_TAB"
                    (click)="moveToFeedbackNotes()"
                    class="nav-link"
                    [ngClass]="{ 'active' :isFeedBackActive()}"
                    data-bs-toggle="tab"
                    data-bs-target="#note-tab"
            >
              Feedback
            </button>
          </li>
        </ul>

        <div class="right_part_filters" *ngIf="shouldShownTimePeriodDropwDown()">
          <div class="completed_members" *ngIf="tabName == TEAM_TAB">
            {{passedTestTeamMemberCount}}/{{selectedTeam?.members}} team members completed the test
          </div>
          <div class="score_options_panel">
            <div  class="dropdown">
              <a
                class="dropdown-toggle time-period"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {{timePeriodNameDefinitor()}}
              </a>
              <ul class="dropdown-menu time-period">
                <li>
                  <a (click)="changeTimePeriod(1)" class="dropdown-item">
                    <p>Month</p>
                    <div class="separator_line"></div>
                  </a>
                </li>
                <li>
                  <a (click)="changeTimePeriod(2)" class="dropdown-item">
                    <p>3 months</p>
                    <div class="separator_line"></div>
                  </a>
                </li>
                <li>
                  <a (click)="changeTimePeriod(3)" class="dropdown-item">
                    <p>6 months</p>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="tab-content" id="myTabContent">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
  <app-sidebar></app-sidebar>
</div>

<app-modal [className]="'need_modal'" *ngIf="openHelp" (closeModal)="onCloseHelp()">
  <h2 class="modal_title">Need Help?</h2>
  <br>
  <p class="modal_text">Our Leader help is divided into Self Guided Help (included) and Professional Consultant Help
    (paid extra).</p>
  <p class="modal_text">Click <span>Professional Help</span> to visit our Expert Consultants page and book a free
    introductory call now. </p>
  <p class="modal_text">OR click <span>Self Help</span> to explore our tools and templates for your self guided help.
  </p>
  <br>
  <div class="card_buttons">
    <a href="https://www.notion.so/temaresources/TEMA-HandBook-edbd59b1530c4d74bd3d2b7570b11c40" target="_blank"
       class="cancel_secondary">Self Help</a>
    <a href="https://www.temaresources.app/sessionpurchase" target="_blank" class="confirm">Professional Help</a>
  </div>
</app-modal>
