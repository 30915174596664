import {Injectable} from '@angular/core';
import {Observable, Subject} from "rxjs";
import {Notification, NotificationStatus} from "../models/notification";

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private myAppEventSubject: Subject<Notification> = new Subject<Notification>();
  public readonly applicationNotification: Observable<Notification> = this.myAppEventSubject.asObservable();
  showNotification(notification: Notification) {
    if(notification.Status)
    this.myAppEventSubject.next(notification);
  }

  showError(message: string): void{
    this.myAppEventSubject.next({
      Message: message,
      Status: NotificationStatus.Error
    } as Notification);
  }

  showWarning(message: string): void{
    this.myAppEventSubject.next({
      Message: message,
      Status: NotificationStatus.Warning
    } as Notification);
  }

  showInfo(message: string): void{
    this.myAppEventSubject.next({
      Message: message,
      Status: NotificationStatus.Info
    } as Notification);
  }
}
