<section class="form_backBtn_section">
  <a class="form_backPageBtn" routerLink="/dashboard">Back to Dashboard</a>
</section>
<app-progress-smr-test [countOfPages]="11" [currentPage]="11"></app-progress-smr-test>
<section class="form_section">
  <div class="form_container">
    <h4 class="form_header">Leave your feedback</h4>
    <p class="form_description">
      Thank you for your ratings of how you feel and how you feel about the
      team.
      <br/><br/>
      You now have the opportunity to provide feedback to your team if you wish
      to comment on any specific rating results, or provide general feedback.
    </p>

    <div *ngFor="let form of formArray.controls; let i = index">
      <form [formGroup]="getForm(i)">
        <div class="feedback_message">
          <p class="form_sectionHeader">Leave feedback for</p>
          <dropdown formControlName="selectedUserId" [Clearable]="false" (change)="selectUser($event)" [Items]="users"></dropdown>
          <div *ngIf="getForm(i).controls['selectedUserId'].invalid && (getForm(i).controls['selectedUserId'].dirty || getForm(i).controls['selectedUserId'].touched)" class="error-block">
            <i class="error-icon" aria-hidden="true"></i>
            <span>Please choose team-member.</span><br>
          </div>
        </div>

        <div class="feedback_message">
          <p class="form_sectionHeader">Feedback</p>
          <textarea
            formControlName="feedback"
            class="form_messageInput"
            name="feedback_text"
            id=""
            cols="30"
            rows="7"
            placeholder="Enter your feedback"
          ></textarea>
        </div>
        <div *ngIf="getForm(i).controls['feedback'].invalid && (getForm(i).controls['feedback'].dirty || getForm(i).controls['feedback'].touched)" class="error-block">
          <i class="error-icon" aria-hidden="true"></i>
          <span>Please share valuable feedback with your team. The field accepts at least 30 characters, with a maximum of 500 characters.</span><br>
        </div>
      </form>
    </div>

    <div>
      <button *ngIf="isAvailableAddMoreFeedback" (click)="addMoreFeedbackToArray()" class="add_more_feedBtn">
        Add more feedback
      </button>
    </div>

    <div class="clearFix"></div>

    <div class="formBtn_container">
      <button (click)="submitFeedback()" class="form_NextBtn">Finish</button>
    </div>
  </div>
</section>

<app-modal [className]="'modal'" *ngIf="openNoteProposal" (closeModal)="onCloseHelp()">
  <h2 class="modal_title">Document your thoughts?</h2>
  <br>
  <p>You can leave yourself a comment, thought, or action that you have taken away.</p>
  <div class="card_buttons">
    <a [routerLink]="toResultUrl" class="cancel_secondary">Skip</a>
    <a [routerLink]="noteUrl" class="confirm">Leave a note</a>
  </div>
</app-modal>
