<div class="smr_feedback">
  <p class="feedback_header">
    <i class="feedback_icon" aria-hidden="true"></i>
    <span class="feedback_title">{{nameOfFeedback}} Feedback</span>
  </p>
  <ul class="feedback_list">
    <li class="feedback_card" *ngIf="feedbacks && feedbacks.length == 0">
      <div class="feedback_msg">
        No feedback left yet.
      </div>
    </li>
    <li class="feedback_card" *ngFor="let item of feedbacks; let i = index">
      <div>
        <div class="card_header">
          <div class="user_info">
            <p class="feedback_msg">{{item.isGiven ? item.toUser : item.fromUser }}</p>
            <p class="datetime">{{ getTeamName(item.roleName)}}{{ item.feedbackDate  | date: 'MMMM dd, yyyy' }}</p>
          </div>
        </div>
        <p class="feedback_msg">{{ item.feedbackMessage }}</p>
        <div
          class="separator" *ngIf="i !== feedbacks.length - 1"
        ></div>
      </div>
  </ul>
  <nav aria-label="Page navigation example">
    <!-- <ul class="pagination">
  <nav>
  <ul class="pagination">
    <li class="page-item active"><a class="page-link">1</a></li>
    <li class="page-item"><a class="page-link">2</a></li>
    <li class="page-item"><a class="page-link">3</a></li>
  </ul> -->
  </nav>
</div>


