import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from "./shared/guards/auth.guard";
import {OnBeforeOnboardingGuard} from "./shared/guards/on-before-unboarding-guard.service";
import {ExpiredSubscriptionGuardGuard} from "./shared/guards/expired-subscription-guard.guard";

const routes: Routes = [
  {
    path: 'dashboard',
    canActivate: [ExpiredSubscriptionGuardGuard, AuthGuard, OnBeforeOnboardingGuard],
    loadChildren: () => import('./dashboard/dashboard.module').then(x => x.DashboardModule)
  },
  {
    path: 'payment',
    canActivate: [AuthGuard, OnBeforeOnboardingGuard],
    loadChildren: () => import('./payment/payment.module').then(x => x.PaymentModule)
  },
  {
    path: 'profile',
    canActivate: [ExpiredSubscriptionGuardGuard, AuthGuard, OnBeforeOnboardingGuard],
    loadChildren: () => import('./profile/profile.module').then(x => x.ProfileModule)
  },
  {
    path: 'smr',
    canActivate: [AuthGuard, OnBeforeOnboardingGuard],
    loadChildren: () => import('./smr/smr.module').then(x => x.SmrModule)
  },
  {
    path: 'onboarding',
    canActivate: [AuthGuard],
    loadChildren: () => import('./onboarding/onboarding.module').then(x => x.OnboardingModule)
  },
  {
    path: 'team',
    canActivate: [ExpiredSubscriptionGuardGuard, AuthGuard, OnBeforeOnboardingGuard],
    loadChildren: () => import('./team/team.module').then(x => x.TeamModule)
  },
  {path: 'signupbyinvite', loadChildren: () => import('./sing-up-by-invite/sing-up-by-invite.module').then(x => x.SingUpByInviteModule)},
  {
    path: 'subscription',
    canActivate: [AuthGuard, OnBeforeOnboardingGuard],
    loadChildren: () => import('./subscription/subscription.module').then(x => x.SubscriptionModule)
  },
  {
    path: 'pit',
    canActivate: [AuthGuard, OnBeforeOnboardingGuard],
    loadChildren: () => import('./psychometric-test/psychometric-test.module').then(x => x.PsychometricTestModule)
  },
  {path: 'admin', loadChildren: () => import('./admin-control/admin-control.module').then(x => x.AdminControlModule)},
  {path: 'not-found', loadChildren: () => import('./not-found/not-found.module').then(x => x.NotFoundModule)},
  {path: '', loadChildren: () => import('./auth/auth.module').then(x => x.AuthModule)},
  {path: '**', loadChildren: () => import('./not-found/not-found.module').then(x => x.NotFoundModule)},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}

