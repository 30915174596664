import {  HTTP_INTERCEPTORS,  HttpEvent,  HttpErrorResponse,} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {  HttpInterceptor,  HttpHandler,  HttpRequest,} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {TokenStorageService} from './token.service';
import {AuthService} from './auth.service';
import {Router} from "@angular/router";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: Observable<any>;

  constructor(public authService: AuthService, private router: Router,  private tokenService: TokenStorageService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const accessToken = this.tokenService.getAccessToken();

    // If an access token is present, add it to the request headers
    if (accessToken) {
      request = this.addToken(request, accessToken);
    }
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          if (!this.isRefreshing) {
            this.isRefreshing = true;
            this.refreshTokenSubject = this.authService.refreshToken().pipe(
              switchMap((token: any) => {
                this.isRefreshing = false;
                this.tokenService.saveToken(token.accessToken);
                this.tokenService.saveRefreshToken(token.refreshToken);
                return next.handle(this.addToken(request, token.accessToken));
              }),
              catchError((err) => {
                this.isRefreshing = false;
                this.router.navigate(["/login"]);
                return throwError(err);
              })
            );
          }
          return this.refreshTokenSubject;
        }
        return throwError(error);
      })
    );
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }
}

export const authInterceptorProviders = [
  {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
];
