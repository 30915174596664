import {Component, Input, OnInit,} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClientService} from 'src/app/shared/services/http-client.service';

@Component({
  selector: 'app-feedback-cards',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss'],
})
export class FeedbackComponent {
  @Input() feedbacks: any[] = [];
  @Input() nameOfFeedback: any;

  constructor() {
  }

  getTeamName(roleName: string) {
    if(roleName == 'tl'){
      return "Team Leader - "
    }

    if(roleName == 'tm'){
      return "Team Member - "
    }

    return "";
  }
}


