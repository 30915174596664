<div class="start_page__wrapper">
<div class="left">
    <div class="form_wrap">
      <h2>Log in to get started</h2>
      <div class="form_container">
        <form [formGroup]="loginForm" (ngSubmit)="submitLogin()" id="form">
            <label for="email">Email</label>
            <input type="text" id="email" formControlName="email" (keyup)="trimValue($event)" placeholder="user@example.com">
            <div *ngIf="this.loginForm.get('email')?.invalid && this.loginForm.get('email')?.touched" class="error-block">
              <i class="error-icon" aria-hidden="true"></i>
              <span>Please enter a valid email address.</span>
            </div>
            <label for="password">Password</label>
            <app-password formControlName="password" id="password"></app-password>
            <div
              *ngIf="this.loginForm.get('password')?.touched && this.loginForm.value?.password?.length != 0 && this.loginForm.get('password')?.invalid"
              class="error-block">
              <i class="error-icon" aria-hidden="true"></i>
              <span>Please enter a longer password,</span><br>
              <span>it has to be at least 8 characters long.</span>
            </div>
            <div *ngIf="this.loginForm.get('password')?.touched && this.loginForm.value?.password?.length == 0" class="error-block">
              <i class="error-icon" aria-hidden="true"></i>
              <span>Please enter your password.</span><br>
            </div>
            <div>
              <a class="reset_pass_link" [routerLink]="['/forgotpassword']"  (click)="goToForgotpassword()"> Forgot your password? </a>
            </div>
            <br>
          <button class="btn-login primary" appPreventDoubleSubmit>Log in to TEMA</button>
        </form>
        <p class="goToLogin">Don't have an account yet? <label routerLink="/signup">Sign up</label></p>
      </div>
    </div>
  </div>
  <div class="right">
    <app-video-intro/>
  </div>
</div>

<app-modal *ngIf="this.showResetPassword" (closeModal)="handleCloseResetpassword()" [className]="'reset_pass'">
  <div class="container modal_cont">
      <div class="set_pass">
        <h2  class="modal_title">Reset your password</h2>
        <div class="form_container">
          <form [formGroup]="forgotPasswordForm" autocomplete="off" novalidate (ngSubmit)="forgotPassword(forgotPasswordForm.value)">
            <label for="email">Your email</label>
            <input type="text" id="email" formControlName="email" (keyup)="trimForgotPasswordFormValue($event)" placeholder="user@example.com">
            <div *ngIf="this.forgotPasswordForm.get('email')?.invalid && this.forgotPasswordForm.get('email')?.touched" class="error-block">
              <i class="error-icon" aria-hidden="true"></i>
              <span>Please enter a valid email address.</span>
            </div>
            <div class="card_buttons reset_pass_btn">
              <button class="confirm" [class.disabled]="!forgotPasswordForm.valid"  type="submit" [disabled]="!forgotPasswordForm.valid">Reset password</button>
            </div>
          </form>
        </div>
    </div>
  </div>
</app-modal>
