import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-progress-smr-test',
  templateUrl: './progress-smr-test.component.html',
  styleUrls: ['./progress-smr-test.component.scss'],
})
export class ProgressSmrTestComponent implements OnInit{
  @Input() currentPage: number;
  @Input() countOfPages: number;
  public pages: number[];

  constructor() {
  }

  ngOnInit(): void {
    this.pages = Array.from({ length: this.countOfPages }, (_, i) => i + 1);
  }
}
