import {Component, Input} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from "@angular/forms";

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: PasswordComponent
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: PasswordComponent
    }
  ]
})
export class PasswordComponent implements ControlValueAccessor, Validator {
  public isShow = true;
  public value: string = '';
  @Input() Placeholder = 'Password'
  onChange = (value: string) => { };
  onTouched = () => { };
  touched = false;

  changeShown() {
    this.isShow = !this.isShow;
    this.markAsTouched();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(obj: string): void {
    this.value = obj;
    this.onChange(this.value);
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const quantity = control.value;
    if (quantity <= 0) {
      return {
        mustBePositive: {
          quantity
        }
      };
    }
    return null;
  }

  onInput($event: any) {
    this.onChange($event.target.value);
  }

  onFocusOut() {
    this.onTouched();
  }
}
