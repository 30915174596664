<div class="pit_container">
  <a routerLink="/dashboard" class="back_to_dashboard"><i class="arrow"></i>
    <p>Back to Dashboard</p></a>
  <div class="test_intro">
    <div class="card">
      <h3 class="card_title">Select your team</h3>
      <p class="card_text">Here you can choose which team you would like to work with.
      </p>


      <form [formGroup]="form" (submit)="submitForm()">
        <label style="margin-bottom: 10px;">Your teams
          <dropdown formControlName="selectedTeamId" [Items]="teams" Placeholder="Select team"  [CloseOnSelect]="true"></dropdown>
        </label>
        <div style="margin-bottom: 10px;" *ngIf="this.form.get('selectedTeamId') as control">
          <div *ngIf="control?.invalid && (control?.touched || control?.dirty)" class="error-block">
            <i class="error-icon" aria-hidden="true"></i>
            <span>Please specify team.</span>
          </div>
        </div>
        <div class="card_buttons">
          <button class="confirm">Continue</button>
        </div>
      </form>
    </div>
  </div>
</div>
