import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appPreventDoubleSubmit]'
})
export class PreventDoubleSubmitDirective {
  private isSubmitting = false;

  constructor(private el: ElementRef) {}

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent): void {
    if (this.isSubmitting) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      this.isSubmitting = true;
      setTimeout(() => {
        this.isSubmitting = false;
      }, 4000); // You can adjust the duration (in milliseconds) according to your needs
    }
  }
}
