<app-header/>
<!-- <div class="header">
  <p class="headerText">TEMA</p>
  <p style="float: right">
    <app-subscription-info class="headerText"></app-subscription-info>

  </p>

</div> -->
<app-notification-component></app-notification-component>
<router-outlet></router-outlet>

