<div class="start_page__wrapper">
  <div class="left">
    <div class="form_wrap">
      <h2>Get started below</h2>
      <div class="form_container">
        <form [formGroup]="signupForm" (ngSubmit)="submitSignup()" id="form">
          <div>
            <label for="email">Email</label>
            <input type="text" id="email"  (keyup)="trimValue($event)" formControlName="email" placeholder="user@example.com">
            <div *ngIf="this.signupForm.get('email')?.invalid && this.signupForm.get('email')?.touched" class="error-block">
              <i class="error-icon" aria-hidden="true"></i>
              <span>Please enter a valid email address. </span>
            </div>
          </div>
          <div>
            <label for="password">Password</label>
            <app-password formControlName="password" id="password"></app-password>
            <div
              *ngIf="this.signupForm.value.password.length != 0 && this.signupForm.get('password')?.invalid && this.signupForm.get('password')?.touched"
              class="error-block">
              <i class="error-icon" aria-hidden="true"></i>
              <span>Please enter a longer password, it has</span><br>
              <span> to be at least 8 characters long.</span>
            </div>
            <div *ngIf="this.signupForm.value.password.length == 0 && this.signupForm.get('password')?.touched" class="error-block">
              <i class="error-icon" aria-hidden="true"></i>
              <span>Please enter your password.</span>
            </div>
          </div>
          <div>
            <label for="confirm_password">Confirm Password</label>
            <app-password formControlName="confirm_password" id="confirm_password" Placeholder="Confirm Password"></app-password>
            <div *ngIf="this.signupForm.value.confirm_password.length == 0 && this.signupForm.get('confirm_password')?.touched"
                 class="error-block">
              <i class="error-icon" aria-hidden="true"></i>
              <span>Please confirm your password.</span><br>
            </div>

            <div
              *ngIf="this.signupForm.value.confirm_password.length != 0 && this.signupForm.value.confirm_password !== this.signupForm.value.password && this.signupForm.get('confirm_password')?.touched"
              class="error-block">
              <i class="error-icon" aria-hidden="true"></i>
              <span>Those passwords don’t match,</span><br>
              <span>please try again.</span>
            </div>
          </div>
          <div class="terms_conditions">
            <input id="terms" formControlName="terms" type="checkbox" checked>
            <label class="checkboxLabel" for="terms">I agree to TEMA <a href="https://www.temaresources.app/privacypolicy" target="_blank">Privacy Policy and Data Processing Agreement.</a></label>
          </div>
          <div *ngIf="!this.signupForm.value.terms && this.signupForm.get('terms')?.touched" class="error-block">
            <i class="error-icon" aria-hidden="true"></i>
            <div class="terms-error">
              <span>Please agree to our Data Processing <br></span>
              <span>Agreement and Privacy Policy</span>
            </div>
          </div>
          <button class="primary" appPreventDoubleSubmit>Create my account</button>
        </form>
        <p class="goToLogin">Already have an account? <label routerLink="/login">Log In</label></p>
      </div>
    </div>
  </div>
  <div class="right">
    <app-video-intro/>
  </div>
</div>

