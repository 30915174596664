import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HttpClientService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };
  constructor(
    private httpClient: HttpClient,
    @Inject('baseUrl') private baseUrl: string
  ) {}

  private url(route: string, id?: string): string {
    let url = this.baseUrl + `/${route}`;
    if (id) {
      url = url + `/${id}`;
    }
    return url;
  }

  get<T>(route: string, id?: string): Observable<T> {
    let url: string = this.url(route, id);
    return this.httpClient.get<T>(url, this.httpOptions);
  }

  post<TResponse>(route: string, body?: any): Observable<TResponse> {
    let url: string = this.url(route);
    return this.httpClient.post<TResponse>(url, body, this.httpOptions);
  }

  put<T>(route: string, body: Partial<T>): Observable<T> {
    let url: string = '';
    url = this.url(route);
    return this.httpClient.put<T>(url, body, this.httpOptions);
  }

  delete<T>(route: string, id: string): Observable<T> {
    let url: string = '';
    url = this.url(route, id);
    return this.httpClient.delete<T>(url, this.httpOptions);
  }
}
