import { WelcomePageComponent } from './welcome/welcome-component';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {AuthRoutingModule} from './auth-routing.module';
import {SignUpOptionsComponent} from './sign-up/sign-up-options.component';
import {SingUpEmailComponent} from './sign-up/sing-up-email/sing-up-email.component';
import {ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {HttpClientModule} from '@angular/common/http';
import {LoginComponent} from './login/login.component';
import {AuthService} from "./services/auth.service";
import {VideoIntroComponent} from './video-intro/video-intro.component';
import {SharedModule} from "../shared/shared.module";
import { ResetPasswordComponent } from './reset-password/reset-password.component';

@NgModule({
  declarations: [
    SignUpOptionsComponent,
    SingUpEmailComponent,
    LoginComponent,
    ResetPasswordComponent,
    VideoIntroComponent,
    WelcomePageComponent
  ],
  providers: [AuthService],
  imports: [
    CommonModule,
    AuthRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    SharedModule
  ]
})
export class AuthModule {
}
