import {Component, EventEmitter, Input, Output} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: DropdownComponent,
    },
  ],
})
export class DropdownComponent implements ControlValueAccessor {
  @Input() Items: any;
  @Input() BindLabel: string = 'name';
  @Input() BindValue: string = 'id';
  @Input() Placeholder: string = 'Empty';
  @Input() CloseOnSelect: boolean = true;
  @Input() Multiple: boolean = false;
  @Input() Loading: boolean = false;
  @Input() Readonly: boolean = false;
  @Input() Clearable: boolean = true;
  @Output() change: EventEmitter<any> = new EventEmitter<any>();

  selectedValue: any;

  propagateChange = (_: any) => {};

  onChange(value: any) {
    this.propagateChange(this.selectedValue);
    this.change.emit(value);
  }

  writeValue(value: string): void {
    if (value !== undefined) {
      this.selectedValue = value;
    }
  }

  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  registerOnTouched() {}
}
