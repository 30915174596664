import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent {
  @Input() className = '';
  @Output() closeModal = new EventEmitter();
  
  public handleCloseModal() {
    this.closeModal.emit();
    console.log('click')
    document.body.classList.remove('modal_open');
  }
}
