import {Injectable} from '@angular/core';
import {firstValueFrom, Observable} from "rxjs";
import {HttpClientService} from "../../shared/services/http-client.service";
import {AvailableTeamsResponse} from "./contracts/availableTeamsResponse";
import {SmrStatus} from "./models/SmrStatus";


@Injectable({
  providedIn: 'root'
})
export class TestService {

  private numberTeamId: number;
  private availableTeamsResponse: AvailableTeamsResponse;


  constructor(private httpService: HttpClientService) {
  }

  async getAvailableTeams() {
    if (this.availableTeamsResponse == null) {
      this.availableTeamsResponse = await firstValueFrom(this.httpService.get<AvailableTeamsResponse>(`api/team/smr`))
      return this.availableTeamsResponse;

    } else {
      return this.availableTeamsResponse;
    }
  }

  getSmrStatus(): Observable<SmrStatus> {
    return this.httpService.get<SmrStatus>(`api/smrtest/available`)
  }

  clearAvailableTeams() {
    this.availableTeamsResponse = null;
  }

  get SelectedTeamId() {
    return this.numberTeamId;
  }

  set SelectedTeamId(newTeamId: number) {
    this.numberTeamId = newTeamId;
  }
}
