import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {firstValueFrom, lastValueFrom, Observable} from 'rxjs';
import {SubscriptionService} from "../services/subscription.service";
import {TokenStorageService} from "../services/token.service";
import {RolesConstants} from "../constants/roles-constants";

@Injectable({
  providedIn: 'root'
})
export class ExpiredSubscriptionGuardGuard implements CanActivate {
  constructor(private subscriptionService: SubscriptionService, private tokenStrorage: TokenStorageService, private router: Router) {
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let subInfo = await firstValueFrom(this.subscriptionService.currentSubscriptionPlan$)
    if (subInfo == null)
      subInfo = await this.subscriptionService.getSubscriptionInfo();
    if (subInfo.active == false) {
      await this.redirectToBuySubscription();
      return false;
    }

    const date = new Date(subInfo.expireIn);
    date.setDate(date.getDate() + 2); // add 2 days to the date

    if (date.getTime() < new Date().getTime()) {
      await this.redirectToBuySubscription();
      return false;
    } else {
      return true;
    }
  }

  private async redirectToBuySubscription() {
    await this.router.navigate(['/subscription']);
  }
}
