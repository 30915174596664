import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {NotFoundComponent} from '../shared/component/not-found-component/not-found/not-found.component';
import {LoginComponent} from './login/login.component';
import {SignUpOptionsComponent} from './sign-up/sign-up-options.component';
import {SingUpEmailComponent} from './sign-up/sing-up-email/sing-up-email.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';

const routes: Routes = [
  {path: 'signup', component: SingUpEmailComponent},
  {path: 'login', component: LoginComponent},
  {path: 'not-found', component: NotFoundComponent},
  {path: 'resetpassword', component: ResetPasswordComponent},
  {path: '', redirectTo: "/dashboard", pathMatch: 'full'},
  {path: '**', redirectTo: "/dashboard", pathMatch: 'full'},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule {
}
