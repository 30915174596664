import { AbstractControl, ValidationErrors } from '@angular/forms';

export function emailValidator(control: AbstractControl): ValidationErrors | null {
  const emailRegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  let valid: boolean = false;
  if(control.value != undefined && control.value != ''){
    valid = emailRegExp.test(control.value);
  }
  else {
    valid = true;
  }
  
  return valid ? null : { email: true };
}
