import {Component, OnInit} from '@angular/core';
import {ShortDropdownListItemModel} from "../../../shared/models/short-dropdown-list-item";
import {Router} from "@angular/router";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {TestService} from "../test.service";

@Component({
  selector: 'app-choose-team',
  templateUrl: './choose-team.component.html',
  styleUrls: ['./choose-team.component.scss']
})
export class ChooseTeamComponent implements OnInit {
  teams: ShortDropdownListItemModel[];
  form: FormGroup

  constructor(private testService: TestService, private router: Router) {
  }

  async ngOnInit() {
    this.form = new FormGroup<any>({
      selectedTeamId: new FormControl(this.testService.SelectedTeamId, [Validators.required])
    });

    let response = await this.testService.getAvailableTeams();
    this.teams = response.teams.map(x => {
      return {name: `${x.name} (${x.countOfMembers} members)`, id: x.id}
    });
    if (response.isTeamExist) {
      if (this.teams.length == 0) {
        await this.router.navigate(['dashboard'])
        return;
      }
    } else {
      await this.router.navigate(['smr/test'])
    }
  }

  submitForm() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    this.testService.SelectedTeamId = this.form.value.selectedTeamId;
    this.router.navigate(['smr/test'])
  }
}
