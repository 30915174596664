import { Injectable } from '@angular/core';
import {OnboardingStatus} from "../../onboarding/models/onboarding-status";
import {firstValueFrom} from "rxjs";
import {HttpClientService} from "./http-client.service";

@Injectable({
  providedIn: 'root'
})
export class OnBoardingStatusService {

  private onboardingStatus: OnboardingStatus | null = null;
  constructor(private httpClient: HttpClientService) { }

  async getStatus(): Promise<OnboardingStatus> {
    if (this.onboardingStatus == null) {
      let status = await firstValueFrom(this.httpClient.get<{ onboardingStatus: number }>('api/onboarding/status'));
      this.onboardingStatus = status.onboardingStatus as OnboardingStatus;
    }

    return this.onboardingStatus;
  }

  setStatus(status:OnboardingStatus){
    this.onboardingStatus = status;
  }
}
