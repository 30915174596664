import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Route, Router} from '@angular/router';
import {HttpClientService} from 'src/app/shared/services/http-client.service';
import {FormBuilder, Validators} from "@angular/forms";
import {Title} from "@angular/platform-browser";


@Component({
  selector: 'app-personal-notes',
  templateUrl: './personal-notes.component.html',
  styleUrls: ['./personal-notes.component.scss']
})
export class PersonalNotesComponent implements OnInit {
  public test: string;
  public showErrorMessage = false;
  form: any;
  private testId: string;

  constructor(private httpClientService: HttpClientService, private activatedRoute: ActivatedRoute, private router: Router, private fb: FormBuilder, private titleService: Title) {

    this.form = this.fb.group({
      note: ['', [Validators.required, Validators.minLength(30), Validators.maxLength(400)]],
    });
  }

  submitFeedback() {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      this.showErrorMessage = true;
      return;
    }
    let requestObject = {
      noteText: this.form.controls.note.value,
      testId: this.testId
    }
    this.httpClientService.post("api/smrtest/note", requestObject)
      .subscribe(
        (response) => {
          this.router.navigateByUrl('smr/result/score');
        }
      )

  }

  ngOnInit(): void {
    this.testId = this.activatedRoute.snapshot.paramMap.get('testId');
    this.titleService.setTitle('SMR notes');
  }
}
