

export interface Notification {
  Message:string;
  Status:NotificationStatus;
}

export enum NotificationStatus {
  Info,
  Warning,
  Error
}
