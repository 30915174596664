<div *ngIf="!hidePopup" class="w-100 notification_container d-flex align-items-center justify-content-center" [ngClass]="{
      'error-text':notification.Status === NotificationStatus.Error,
      'warning': notification.Status === NotificationStatus.Warning,
      'info': notification.Status === NotificationStatus.Info
    }">
  <!-- <div class="container">
    <div class="row align-items-center justify-content-center">
      <div class="col-xl-7 col-9"><label>{{notification.Message}}</label></div>
      <div class="col-xl-1 col-3 align-items-center"><label (click)="closePopup()" class="dismiss">Dismiss</label></div>
    </div>
  </div> -->
    <div class="notification alert-dismissible fade show" role="alert">
    <i [ngClass]="{
      'error-icon':notification.Status === NotificationStatus.Error,
      'warning-icon': notification.Status === NotificationStatus.Warning,
      'info-icon': notification.Status === NotificationStatus.Info
    }" aria-hidden="true"></i>
    <span>{{notification.Message}}</span>
    <label class="close_btn" data-bs-dismiss="alert" (click)="closePopup()" aria-label="Close">
      <i class="close_icon" [ngClass]="{
        'error':notification.Status === NotificationStatus.Error,
        'warning': notification.Status === NotificationStatus.Warning,
        'info': notification.Status === NotificationStatus.Info
      }" aria-hidden="true"></i>
    </label>
  </div>

</div>
