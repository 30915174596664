import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-popup-component',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})

export class PopupComponent {
  @Input() content = '';
  @Input() heading = '';
  @Input() testResultDescription = '';
  @Input() metricsMessage = '';
  closePopup: boolean = true;

  public handleClosePopup() {
    this.closePopup = true;
    document.body.style.overflowY = "scroll"
  }
  replaceMethod(text:string){
    return text?.replaceAll('@', '<br/><br/>');
  }
  handleOpenPopup(content: string, heading: string) {
    this.content = content;
    this.heading = heading;
    this.closePopup = false;
    document.body.style.overflowY = "hidden"
  }

  goToLink(url: string){
    window.open(url, "_blank");
  }
}
