import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {AuthResponseData} from '../models/auth-response-data.model';
import {Router} from '@angular/router';
import {User} from '../models/user.model';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {environment} from "../../../environments/environment";
import {TokenStorageService} from "../../shared/services/token.service";
import { ForgotPassword } from '../models/forgot-password';
import { ResetPasswordState } from '../reset-password/reset-password-state';
import {HttpClientService} from "../../shared/services/http-client.service";

@Injectable()
export class AuthService {

  user = new BehaviorSubject<User | null>(null);

  constructor(private http: HttpClient, private tokenService: TokenStorageService, private httpService: HttpClientService) {
  }

  signupByEmail(email: string, password: string, confirm: string): Observable<AuthResponseData> {
    return this.http
      .post<AuthResponseData>(
        `${environment.apiUrl}/Auth/register`,
        {
          email: email,
          password: password,
          confirmPassword: confirm
        },
        {headers: {"Content-Type": "application/json"}}
      ).pipe(tap({
        next: val => {
          this.tokenService.saveToken(val.accessToken);
          this.tokenService.saveRefreshToken(val.refreshToken);
        }
      }));
  }

  login(email: string, password: string): Observable<AuthResponseData> {
    return this.http
      .post<AuthResponseData>(
        `${environment.apiUrl}/Auth/login`,
        {
          email: email,
          password: password,
        }
      ).pipe(tap({
        next: val => {
          this.tokenService.saveToken(val.accessToken);
          this.tokenService.saveRefreshToken(val.refreshToken);
        }
      }));
  }

  forgotPassword(forgotPassword: ForgotPassword): Observable<any> {
    return this.http
        .post(
        `${environment.apiUrl}/Auth/forgot-password`,
        {
           email: forgotPassword.email,
        },
        {headers: {"Content-Type": "application/json"}}
      );
  }

  getResetPasswordState(userId: string, code: string): Observable<{ state:ResetPasswordState}> {
    return this.httpService.get(`api/resetPassword/${userId}?code=${code}`);
  }

  resetPasswordByEmail(userId: string, code: string, password: string): Observable<any> {
    let request = {
      Code: code,
      Password: password,
      UserId: userId
    };
    return this.httpService.post(`Auth/reset-password`, request).pipe(tap({
      next: val => {
        this.tokenService.saveToken(val.accessToken);
        this.tokenService.saveRefreshToken(val.refreshToken);
      }
    }));
  }
}
