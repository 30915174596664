<div class="score">
  <div class="score_wrap">
    <div class="score__title">
      <i class="prize_icon" aria-hidden="true"></i>
      <h3>Overall score</h3>
      <!-- dynamic value -->
    </div>
    <div class="score__info">
      <div class="score__chart">
        <!-- implement chart -->
        <div class="chart">
          <p class="result" *ngIf="scoreResult?.overallScore > 0">{{ scoreResult?.overallScore?.toFixed(1)  }}/5</p>
          <p class="result" *ngIf="scoreResult?.overallScore == 0"> 0 </p>
        </div>
        <ul class="chart__desc">
          <li *ngIf="!scoreResult?.isFirstTest && !!scoreResult?.platformComporationFirst">
            <!-- could be dynamic -->
            <i class="starredeyes_icon" aria-hidden="true"></i>
            <p>
               {{scoreResult?.overallScore != 0 ? scoreResult?.platformComporationFirst : "No data for current period selection." }}
            </p>
          </li>
          <li *ngIf="!!scoreResult?.ratingPercentage">
            <i class="star_icon"></i>
            <p>
              {{ scoreResult?.ratingPercentage }}
            </p>
          </li>
          <li *ngIf="!!scoreResult?.greaterThenPercentage">
            <i class="star_icon"></i>
            <p>
              {{ scoreResult?.greaterThenPercentage }}
            </p>
          </li>
        </ul>
      </div>
      <div class="description">
        <p [innerHTML]="replaceMethod(scoreResult?.testResultDescription)" class="description__text">

        </p>
        <!-- <div class="answers__desc">
          <p>
            It’s a great result! Our target that we suggest people is 4.8 on overall score over time (3-6 months). Keep going and you will excel your communication skills!<br>
            We suggest you to read these articles to find out how you can keep and excel your score of communication in a team:
          </p>
          <ul>
            <li>Find your people</li>
            <li>Find your true happiness</li>
            <li>Find what makes you a good communicator</li>
          </ul>
        </div> -->
        <app-popup-component *ngIf="scoreResult?.overallScore != 0"
          [metricsMessage]="scoreResult?.metricsMessage"
          [testResultDescription]="scoreResult?.testResultDescription"
          [heading]="heading"
          [content]="content"
        ></app-popup-component>
      </div>
    </div>
  </div>
  <div class="score_wrap">
    <div class="score__title">
      <i class="scorelist_icon"></i>
      <h3>Score list</h3>
    </div>
    <p class="subtitle">Your answers:</p>
    <div class="answers">
      <div class="answers__chart">
        <div class="description_panel" *ngIf="scoreResult?.overallScore > 0">
          <div class="panel_wrap">
            <div class="panel">
              <div
                class="user_result"
                [ngStyle]="{ 'width.%': (scoreResult?.hapinessScore * 100) / 5 }">
                <p class="skill">Happiness Rating</p>
              </div>
              <div
                class="line"
                [ngStyle]="{
                  'left.%':
                    ((getSuggestedLogic('Happiness')) * 100) / 5 > 100
                      ? 100
                      : ((getSuggestedLogic('Happiness')) * 100) / 5
                }"
              ></div>
              <div  class="skill_tooltip">
                <p>
                  Suggested:
                  <span>{{ getSuggestedLogic('Happiness') }}</span>
                </p>
              </div>
            </div>
            <p class="skill_score">{{ scoreResult?.hapinessScore }}</p>
          </div>
          <div
            class="panel_wrap"
          >
            <div class="panel">
              <div
                class="user_result"
                [ngStyle]="{ 'width.%': (scoreResult?.trustScore * 100) / 5 }"
              >
                <p class="skill">Trust Rating</p>
              </div>
              <div
                class="line"
                [ngStyle]="{
                  'left.%':
                    ((getSuggestedLogic('Trust')) * 100) / 5 > 100
                      ? 100
                      : ((getSuggestedLogic('Happiness')) * 100) / 5
                }"
              ></div>
              <div  class="skill_tooltip">
                <p>
                  Suggested:
                  <span>{{ getSuggestedLogic('Trust') }}</span>
                </p>
              </div>
            </div>
            <p class="skill_score">{{ scoreResult?.trustScore }}</p>
          </div>
          <div
            class="panel_wrap"
          >
            <div class="panel">
              <div
                class="user_result"
                [ngStyle]="{ 'width.%': (scoreResult?.communicationScore * 100) / 5 }"
              >
                <p class="skill">Communication Rating</p>
              </div>
              <div
                class="line"
                [ngStyle]="{
                  'left.%':
                    ((getSuggestedLogic('Communication')) * 100) / 5 > 100
                      ? 100
                      : ((getSuggestedLogic('Communication')) * 100) / 5
                }"
              ></div>
              <div  class="skill_tooltip">
                <p>
                  Suggested:
                  <span>{{ getSuggestedLogic('Communication') }}</span>
                </p>
              </div>
            </div>
            <p class="skill_score">{{ scoreResult?.communicationScore }}</p>
          </div>
          <div
            class="panel_wrap"
          >
            <div class="panel">
              <div
                class="user_result"
                [ngStyle]="{ 'width.%': (scoreResult?.impactScore * 100) / 5 }"
              >
                <p class="skill">Impact Rating</p>
              </div>
              <div
                class="line"
                [ngStyle]="{
                  'left.%':
                    ((getSuggestedLogic('Impact')) * 100) / 5 > 100
                      ? 100
                      : ((getSuggestedLogic('Impact')) * 100) / 5
                }"
              ></div>
              <div  class="skill_tooltip">
                <p>
                  Suggested:
                  <span>{{ getSuggestedLogic('Impact') }}</span>
                </p>
              </div>
            </div>
            <p class="skill_score">{{ scoreResult?.impactScore }}</p>
          </div>

          <div
            class="panel_wrap"
          >
            <div class="panel">
              <div
                class="user_result"
                [ngStyle]="{ 'width.%': (scoreResult?.empowermentScore * 100) / 5 }"
              >
                <p class="skill">Empowerment Rating</p>
              </div>
              <div
                class="line"
                [ngStyle]="{
                  'left.%':
                    ((getSuggestedLogic('Empowerment')) * 100) / 5 > 100
                      ? 100
                      : ((getSuggestedLogic('Empowerment')) * 100) / 5
                }"
              ></div>
              <div  class="skill_tooltip">
                <p>
                  Suggested:
                  <span>{{ getSuggestedLogic('Empowerment') }}</span>
                </p>
              </div>
            </div>
            <p class="skill_score">{{ scoreResult?.empowermentScore }}</p>
          </div>

          <div
            class="panel_wrap"
          >
            <div class="panel">
              <div
                class="user_result"
                [ngStyle]="{ 'width.%': (scoreResult?.compensationScore * 100) / 5 }"
              >
                <p class="skill">Compensation Rating</p>
              </div>
              <div
                class="line"
                [ngStyle]="{
                  'left.%':
                    ((getSuggestedLogic('Compensation')) * 100) / 5 > 100
                      ? 100
                      : ((getSuggestedLogic('Compensation')) * 100) / 5
                }"
              ></div>
              <div  class="skill_tooltip">
                <p>
                  Suggested:
                  <span>{{ getSuggestedLogic('Compensation') }}</span>
                </p>
              </div>
            </div>
            <p class="skill_score">{{ scoreResult?.compensationScore }}</p>
          </div>

          <div
            class="panel_wrap"
          >
            <div class="panel">
              <div
                class="user_result"
                [ngStyle]="{ 'width.%': (scoreResult?.supportScore * 100) / 5 }"
              >
                <p class="skill">Support Rating</p>
              </div>
              <div
                class="line"
                [ngStyle]="{
                  'left.%':
                    ((getSuggestedLogic('Support')) * 100) / 5 > 100
                      ? 100
                      : ((getSuggestedLogic('Support')) * 100) / 5
                }"
              ></div>
              <div  class="skill_tooltip">
                <p>
                  Suggested:
                  <span>{{ getSuggestedLogic('Support') }}</span>
                </p>
              </div>
            </div>
            <p class="skill_score">{{ scoreResult?.supportScore }}</p>
          </div>

          <div
            class="panel_wrap"
          >
            <div class="panel">
              <div
                class="user_result"
                [ngStyle]="{ 'width.%': (scoreResult?.cultureScore * 100) / 5 }"
              >
                <p class="skill">Culture Rating</p>
              </div>
              <div
                class="line"
                [ngStyle]="{
                  'left.%':
                    ((getSuggestedLogic('Culture')) * 100) / 5 > 100
                      ? 100
                      : ((getSuggestedLogic('Culture')) * 100) / 5
                }"
              ></div>
              <div  class="skill_tooltip">
                <p>
                  Suggested:
                  <span>{{ getSuggestedLogic('Culture') }}</span>
                </p>
              </div>
            </div>
            <p class="skill_score">{{ scoreResult?.cultureScore }}</p>
          </div>

          <div
            class="panel_wrap"
          >
            <div class="panel">
              <div
                class="user_result"
                [ngStyle]="{ 'width.%': (scoreResult?.collaborationScore * 100) / 5 }"
              >
                <p class="skill">Collaboration Rating</p>
              </div>
              <div
                class="line"
                [ngStyle]="{
                  'left.%':
                    ((getSuggestedLogic('Collaboration')) * 100) / 5 > 100
                      ? 100
                      : ((getSuggestedLogic('Collaboration')) * 100) / 5
                }"
              ></div>
              <div  class="skill_tooltip">
                <p>
                  Suggested:
                  <span>{{ getSuggestedLogic('Collaboration') }}</span>
                </p>
              </div>
            </div>
            <p class="skill_score">{{ scoreResult?.collaborationScore }}</p>
          </div>

          <div
            class="panel_wrap"
          >
            <div class="panel">
              <div
                class="user_result"
                [ngStyle]="{ 'width.%': (scoreResult?.momentumScore * 100) / 5 }"
              >
                <p class="skill">Momentum Rating</p>
              </div>
              <div
                class="line"
                [ngStyle]="{
                  'left.%':
                    ((getSuggestedLogic('Momentum')) * 100) / 5 > 100
                      ? 100
                      : ((getSuggestedLogic('Momentum')) * 100) / 5
                }"
              ></div>
              <div class="description_personal_scores" *ngIf="countOfTeams > 1 && !isTeam" >
                <p>This is the collective outcome of the answers that you provided during the team assessments.</p>
              </div>
              <div  class="skill_tooltip">
                <p>
                  Suggested:
                  <span>{{ getSuggestedLogic('Momentum') }}</span>
                </p>
              </div>
            </div>
            <p class="skill_score">{{ scoreResult?.momentumScore }}</p>
          </div>
        </div>
        <div class="description_panel" *ngIf="scoreResult?.overallScore == 0">
          <div class="panel_wrap">
            <div class="panel">
              <div class="user_result_not_exist">
                <p class="skill">Happiness + 9 more metrics</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
