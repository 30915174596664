import { Router } from '@angular/router';
import { Component, Input, Output, EventEmitter } from '@angular/core';
@Component({
  selector: 'app-subscriptions-popup',
  templateUrl: './subscription-popup.html',
  styleUrls: ['./subscription-popup.scss']
})

export class SubscriptionsPopup {
  @Input() isTrial = true;
  @Input() subscription = '';
  @Input() popupOpen = false;
  @Output() popupClose = new EventEmitter();

  constructor(private router: Router) { };

  closePopup() {
    this.popupClose.emit();
  }
  goToSubscriptions() {
    this.popupClose.emit();
    this.router.navigate(['subscription']);
  }
  
}