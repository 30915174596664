import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import {SubscriptionService} from "../../services/subscription.service";
import {SubscriptionPlan} from "../../models/subscription-plan";
import {firstValueFrom} from "rxjs";
import {filter} from "rxjs/operators";
import {HttpClientService} from "../../services/http-client.service";
import { TokenStorageService } from '../../services/token.service';
import { DisplayRoleHelper } from '../../helpers/display-role-helper';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  openOpts = false;
  public planName: string;
  public daysLeft =14;
  public isShowDays: boolean;
  email: any;
  username: string;
  openPopup = false;
  openTrial = false;
  trial = 'trial_modal'
  isConsultant = false;


  // leaveModalOpen = false;
  public subPlan: SubscriptionPlan;
  constructor(private router: Router,
    private subService: SubscriptionService,
    private tokenService: TokenStorageService,
    private httpClient: HttpClientService) { }




    async ngOnInit() {
    await this.subService.currentSubscriptionPlan$.subscribe(async subInfo => {
      if(!subInfo || !subInfo.active){
        this.planName = '';
        return;
      }
      let user  =await firstValueFrom( this.httpClient.get<any>('api/users/info'));
      this.email = user.email;
      this.username = user.name;
      this.subPlan = subInfo.currentPlan;
      this.isShowDays = this.isTrial(subInfo.currentPlan);
      this.planName = this.getSubscriptionPlanName(subInfo.currentPlan);
      this.daysLeft = Math.ceil(((new Date(subInfo.expireIn).getTime() - new Date().getTime()) / (1000 * 3600 * 24)));
      this.isConsultant = this.subPlan == 2;
    });
  }

  get userRole(): string {
    const userRole = this.tokenService.getUserRoles();
    return DisplayRoleHelper.getDisplayRole(userRole);
  }

  handleToggleOpts() {
    this.openOpts = !this.openOpts;
  }
  // @Output() onLogout: EventEmitter<boolean> = new EventEmitter();
  @Output() onTrial = new EventEmitter()
  @Output() onSubscription = new EventEmitter();
  SubscriptionPlan = SubscriptionPlan;

  onLogoClick() {
    this.router.navigate(['dashboard']);
  }

  onTrialClick() {
    this.onTrial.emit();
    this.openOpts = false;
  }
  onSubscriptionClick() {
    this.onSubscription.emit();
    this.openOpts = false;
  }

  onProfileClick() {
    this.handleToggleOpts();
    this.router.navigate([`profile/view/${this.tokenService.getUserId()}`])
  }

  onSubscriptionsClick() {
    this.handleToggleOpts();
    this.router.navigate(['subscription'])
  }

  onNewsClick() {
    this.handleToggleOpts();
    window.open('https://www.notion.so/temaresources/What-s-New-a6412937b039417fa04a2130ad81ab74?pvs=4')
  }

  onLogoutClick() {
    this.handleToggleOpts();
    this.subService.clean();
    this.tokenService.signOut();
    // this.leaveModalOpen = true;
    // this.onLogout.emit(this.leaveModalOpen);
    this.router.navigate(['login'])
  }

  getSubscriptionPlanName(planId: number): string {
    switch (planId) {
      case 0:
        return 'Trial';
      case 1:
        return 'Pro';
      case 2:
        return 'Expert';
      default:
        return 'Unknown';
    }
  }

  getLabelColor(daysLeft: number) {
    if (daysLeft > 14) {
      return 'grey';
    } else if (daysLeft > 7) {
      return 'orange';
    } else {
      return 'red';
    }
  }

  private isTrial(currentPlan: SubscriptionPlan): boolean {
    return currentPlan == SubscriptionPlan.Trial;
  }

    onOpenPopup() {
      this.openPopup = true;
      console.log('click')
    // this.openTrial = false;
  }

  onClosePopup() {
    this.openPopup = false;
  }

  goToSubscriptions() {
    this.openTrial = false;
    this.router.navigate(['subscription'])
  }

      openTrialModal() {
    this.openTrial = true;
    this.openPopup = false;
  }

  closeTrialModal() {
    this.openTrial = false;
  }

}
