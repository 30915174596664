<app-modal [className]="'smr_welcome'" *ngIf="openModal" (closeModal)="handleClose()">
  <h2 class="modal_title">
    Welcome to your Success Metrics Rating (SMR)
  </h2>
  <p>The SMR is an Empathetic Intelligence (EI) approach to understand how the team feel about the current work environment. It is made up of 10 vital metrics and the questions are designed to allow the test taker to be honest and think about their answer. You will gain a valuable visible result on your dashboard either visible or anonymous allowing the team to be truthful in their submissions.</p>
  <p>You can choose to set the frequency of the SMR test with your team. TEMA recommends each month, however soon you will be able to select quarterly or annually.  </p>
  <p>You will have the opportunity to provide ratings for both yourself and how you feel, as well as ratings for your overall team. </p>
  <p>Please answer as open and honestly as possible to effect real change. Personal ratings are confidential, only overall scores are visible.</p>
  <div class="card_buttons"><button class="confirm" (click)="handleRedirect()">Begin now</button></div>
</app-modal>
