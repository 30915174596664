<ng-select
  [(ngModel)]="selectedValue"
  (change)="onChange($event)"
  [items]="Items"
  [placeholder]="Placeholder"
  [bindLabel]="BindLabel"
  [bindValue]="BindValue"
  [closeOnSelect]="CloseOnSelect"
  [multiple]="Multiple"
  [loading]="Loading"
  [readonly]="Readonly"
  [clearable]="Clearable"
  loadingText="Loading..."
>
</ng-select>
