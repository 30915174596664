import {Injectable} from '@angular/core';
import {ISmrTestGetResultResponse} from "./contracts/smr-test-get-result-response";
import {HttpClientService} from "../../shared/services/http-client.service";
import {BehaviorSubject, firstValueFrom} from "rxjs";

@Injectable({
  providedIn: 'root'
})

export class SmrService {
  private scoreResultBehaviourSubject = new BehaviorSubject<ISmrTestGetResultResponse>(null);
  public scoreResults$ = this.scoreResultBehaviourSubject.asObservable();


  constructor(private httpClientService: HttpClientService) {

  }

  async loadNewScoreResultData(daterange: string, teamId: string | null = null) {
    let url = `api/smrtest/result?DateRangeType=${daterange}${teamId != null ? `&teamId=${teamId}` : ''}`
    let response = await firstValueFrom(this.httpClientService.get<ISmrTestGetResultResponse>(url));
    response.testResultDescription = response.testResultDescription?.replaceAll('@', '<br/> <br/>');
    this.scoreResultBehaviourSubject.next(response);
  }
}
