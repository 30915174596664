import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {firstValueFrom} from "rxjs";
import {HttpClientService} from "../../../shared/services/http-client.service";

@Component({
  selector: 'app-smrResults-feedback',
  templateUrl: './feedbackTabs.component.html',
  styleUrls: ['./feedbackTabs.component.scss']
})

export class FeedbackTabsComponent implements OnInit {
  isGiven: boolean;
  public givenFeedbacks: any[];
  public receivedFeedbacks: any[];

  constructor(private router: Router, private httpClientService: HttpClientService, private activateRouter: ActivatedRoute) {

  }

  async ngOnInit() {
    let selectedTeam = this.activateRouter.parent.snapshot.paramMap.get("teamId");
    let url = `api/smrtest/feedbacks?teamId=${selectedTeam}`;
    let feedbackResponse = await firstValueFrom(this.httpClientService.get<any>(url));
    this.givenFeedbacks = feedbackResponse.feedbacks.filter(x=>x.isGiven);
    this.receivedFeedbacks = feedbackResponse.feedbacks.filter(x=>!x.isGiven);
  }

  switchToReceived() {
    this.isGiven = false;
  }

  switchToGiven() {
    this.isGiven = true;
  }


}
