import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {AuthService} from "../services/auth.service";
import {NotificationService} from "../../shared/services/notification.service";
import {NotificationStatus} from "../../shared/models/notification";
import {emailValidator} from "../../shared/validators/emailValidator";
import {HttpErrorResponse} from '@angular/common/http';
import {ForgotPassword} from '../models/forgot-password';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm!: FormGroup;
  forgotPasswordForm!: FormGroup;
  public showResetPassword = false;

  constructor(private authService: AuthService, private titleService: Title, private notificationService: NotificationService, private router: Router) {
  }

  ngOnInit(): void {
    this.titleService.setTitle('Log in | TEMA');
    this.loginForm = new FormGroup({
      'email': new FormControl('', [Validators.required, emailValidator]),
      'password': new FormControl('', [Validators.required, Validators.minLength(8)])
    });

    this.forgotPasswordForm = new FormGroup({
      'email': new FormControl('', [Validators.required, emailValidator])
    })
  }

  submitLogin() {
    if (this.loginForm.invalid) {
      this.loginForm.markAllAsTouched();
      return;
    }

    const email = this.loginForm.value.email;
    const password = this.loginForm.value.password;
    this.authService.login(email, password).subscribe(
      {
        next:
          (resData) => {
            this.router.navigate(['/']);
          },
        error: (errorMessage) => {
          this.notificationService.showNotification({
            Status: NotificationStatus.Warning,
            Message: errorMessage.error ?? "Something went wrong"
          })
        }
      }
    );
  }

  trimValue($event: any) {
    this.loginForm.controls['email'].setValue($event.target.value.trim().toLowerCase());
  }

  public forgotPassword = (forgotPasswordFormValue) => {
    const forgotPass = {...forgotPasswordFormValue};
    const forgotPassDto: ForgotPassword = {
      email: forgotPass.email
    }
    this.authService.forgotPassword(forgotPassDto)
      .subscribe({
        next: (_) => {
          this.notificationService.showInfo("An email has been sent with instructions to reset your password.");
        },
        error: (err: HttpErrorResponse) => {
          if (err.error.length > 0) {
            this.notificationService.showWarning(err.error[0].Message);
          } else {
            this.notificationService.showWarning(err.message);
          }
        }
      })
    this.handleCloseResetpassword();
  }

  trimForgotPasswordFormValue($event: any) {
    this.forgotPasswordForm.controls['email'].setValue($event.target.value.trim().toLowerCase());
  }

  goToForgotpassword() {
    this.showResetPassword = true;
  }

  handleCloseResetpassword() {
    this.showResetPassword = false;
    this.forgotPasswordForm.reset();
  }
}
