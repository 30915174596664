import { GraphComponent } from './component/graph/graph.component';
import {SubscriptionsPopup} from './component/subscriptions-popup/subscription-popup';
import {ModalComponent} from './component/modal/modal.component';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TokenStorageService} from "./services/token.service";
import {PasswordComponent} from './component/password-comopnent/password.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NotificationComponent} from './component/nofiticaion-component/notificaion-component.component';
import {DropdownComponent} from './component/dropdown-component/dropdown/dropdown.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {PreventDoubleSubmitDirective} from './directives/prevent-double-submit.directive';
import {HeaderComponent} from './component/header/header.component';
import {NotFoundComponent} from './component/not-found-component/not-found/not-found.component';
import {CustomTooltipComponent} from './component/custom-tooltip/custom-tooltip.component';
import {HtmlToltipDirective} from './directives/html-toltip.directive';
import {SubscriptionInfoComponent} from './component/subscription-info/subscription-info.component';
import {RouterLink} from "@angular/router";
import {RightSidebarComponent} from "./component/right-sidebar/right-sidebar.component";
import {TakeSmrTestModalComponent} from "./component/take-test-modal/take-test-modal";
import { NgxChartsModule } from '@swimlane/ngx-charts';

@NgModule({
  declarations: [
    PasswordComponent,
    NotificationComponent,
    DropdownComponent,
    ModalComponent,
    PreventDoubleSubmitDirective,
    HeaderComponent,
    NotFoundComponent,
    RightSidebarComponent,
    SubscriptionInfoComponent,
    CustomTooltipComponent,
    HtmlToltipDirective,
    SubscriptionsPopup,
    TakeSmrTestModalComponent,
    GraphComponent
  ],
  providers: [TokenStorageService],
  exports: [
    PasswordComponent,
    NotificationComponent,
    DropdownComponent,
    ModalComponent,
    HeaderComponent,
    PreventDoubleSubmitDirective,
    CustomTooltipComponent,
    HtmlToltipDirective,
    RightSidebarComponent,
    SubscriptionsPopup,
    SubscriptionInfoComponent,
    TakeSmrTestModalComponent,
    GraphComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    RouterLink,
    NgxChartsModule
  ]
})
export class SharedModule {
}
