<div class="smr_feedback">
  <p class="feedback_header">
    <i class="feedback_icon" aria-hidden="true"></i>
    <span class="feedback_title">Personal notes</span>
  </p>
  <ul class="feedback_list">
    <li class="feedback_card" *ngIf="notes?.length == 0">
      <div class="feedback_msg">
        No notes left yet.
      </div>
    </li>
    <li  class="feedback_card" *ngFor="let item of notes; let i = index">
      <div *ngIf="!isPersonal">
        <div class="card_header">
          <div class="user_info">
            <p class="datetime">{{ item.dateCreated  | date: 'MMMM dd, yyyy' }}</p>
          </div>
        </div>
        <p class="feedback_msg">{{ item.text }}</p>
        <div
          class="separator"   *ngIf="i !== notes.length - 1"
        ></div>
      </div>
  </ul>
  <nav aria-label="Page navigation example">
    <!-- <ul class="pagination">
  <nav>
  <ul class="pagination">
    <li class="page-item active"><a class="page-link">1</a></li>
    <li class="page-item"><a class="page-link">2</a></li>
    <li class="page-item"><a class="page-link">3</a></li>
  </ul> -->
  </nav>
</div>


