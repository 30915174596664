import {Injectable} from '@angular/core';
import {HttpClientService} from "./http-client.service";
import {BehaviorSubject, firstValueFrom, Observable} from "rxjs";
import { RightSidebarInfo } from '../models/right-sidebar-info';

@Injectable({
  providedIn: 'root'
})
export class RightSidebarService {
    constructor(private httpClient: HttpClientService) {
    }

    async getSidebarData(){
        return await firstValueFrom(this.httpClient.get<RightSidebarInfo>(`api/sidebar`));
    }
}


