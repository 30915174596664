import {Injectable} from '@angular/core';
import {HttpClientService} from "./http-client.service";
import {BehaviorSubject, firstValueFrom, Observable} from "rxjs";
import {SubscriptionPlan} from "../models/subscription-plan";
import {SubscriptionType} from "../models/subscription-type";

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  public currentSubscriptionPlanSource = new BehaviorSubject<SubscriptionInfo>(null);
  currentSubscriptionPlan$ = this.currentSubscriptionPlanSource.asObservable();

  constructor(private httpClient: HttpClientService) {
  }

  async getSubscriptionInfo(): Promise<SubscriptionInfo> {
    let result = await firstValueFrom(this.httpClient.get<SubscriptionInfo>("api/subscription/info"));

    this.currentSubscriptionPlanSource.next(result);
    return result;
  }

  async getNewSubscriptionInfo(): Promise<SubscriptionInfo> {
    let result = await firstValueFrom(this.httpClient.get<SubscriptionInfo>("api/subscription/info"));
    this.currentSubscriptionPlanSource.next(result);
    return result;
  }

  async clean() {
    this.currentSubscriptionPlanSource.next(null);
  }
}

export interface SubscriptionInfo {
  active: boolean,
  expireIn: Date,
  currentPlan: SubscriptionPlan
  countOfMembers: number,
  price: number
  type: SubscriptionType
}
