
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthModule } from './auth/auth.module';
import {TokenStorageService} from "./shared/services/token.service";
import {AuthService} from "./shared/services/auth.service";
import {AuthInterceptor} from "./shared/services/auth.interceptor";
import {environment} from "../environments/environment";
import {SharedModule} from "./shared/shared.module";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientService } from './shared/services/http-client.service';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {SmrModule} from "./smr/smr.module";
@NgModule({
  declarations: [AppComponent],
  imports: [BrowserModule, AppRoutingModule, AuthModule, NgbModule, SmrModule,HttpClientModule, FormsModule, SharedModule, BrowserAnimationsModule],
  providers: [
    TokenStorageService,
    HttpClientService,
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },

    { provide: 'baseUrl', useValue: environment.apiUrl, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
