import { RolesConstants } from "../constants/roles-constants";

export class DisplayRoleHelper {
    private static roles: string[] =
    [
        RolesConstants.CONSULTANT,
        RolesConstants.TEAM_LEADER,
        RolesConstants.TEAM_MEMBER,
        RolesConstants.INDIVIDUAL_MEMBER,
    ];

    private static roleNames: string[] =
    [
        "Consultant",
        "Team Leader",
        "Team Member",
        "Individual Member"
    ];

    static getDisplayRole(role: string){
        var index =  this.roles.findIndex((element) => element === role);
        return this.roleNames[index];
    }
}
