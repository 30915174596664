<div class="dashboard_popup" *ngIf="popupOpen && isTrial">
  <button class="btn_close" (click)="closePopup()"><i class="close_btn_icon"></i></button>
  <h2 class="popup_title">You are on our Trial Plan </h2>
  <p class="popup_text">If you upgraded your subscription with TEMA for only £7.79 per member per month if paid annually, you could:</p>
  <ul>
    <li>
      <i class="check_blue"></i>
      <p>Create more teams and get greater insights.</p>
    </li>
    <li>
      <i class="check_blue"></i>
      <p>Access more Intelligence tools, metrics and recommendations.</p>
    </li>
    <li>
      <i class="check_blue"></i>
      <p>Access priority support and priority response from TEMA Support.</p>
    </li>
    <li>
      <i class="check_blue"></i>
      <p>Access more TEMA HandBook templates and processes.</p>
    </li>
    <li>
      <i class="check_blue"></i>
      <p>Access higher discounts to additional training, intelligence tokens and tools</p>
    </li>
  </ul>
  <div class="card_buttons"><button class="confirm" (click)="goToSubscriptions()">Find your ideal TEMA plan</button></div>
</div>

<!-- <div class="dashboard_popup" *ngIf="popupOpen && subscription === 'pro'">
  <button class="btn_close" (click)="closePopup()"><i class="close_btn_icon"></i></button>
  <h2 class="popup_title">You are on our Pro Plan for medium sized and growing teams.</h2>
  <p class="popup_text">If you upgraded your subscription with TEMA for only £X additional per member per month, you could:</p>
  <ul>
    <li>
      <i class="check_blue"></i>
      <p>If you upgraded your subscription with TEMA for only £X additional per member per month, you could:</p>
    </li>
    <li>
      <i class="check_blue"></i>
      <p>Access unlimited Intelligence tools, metrics and recommendations.</p>
    </li>
    <li>
      <i class="check_blue"></i>
      <p>Access TEMA Concierge and higher priority support</p>
    </li>
    <li>
      <i class="check_blue"></i>
      <p>Access unlimited TEMA HandBook templates and processes (as well we request custom templates)</p>
    </li>
    <li>
      <i class="check_blue"></i>
      <p>Access higher discounts to additional training, intelligence tokens and tools.</p>
    </li>
  </ul>
  <div class="card_buttons"><button class="confirm" (click)="goToSubscriptions()">Find your ideal TEMA plan</button></div>
</div> -->

<div class="dashboard_popup" *ngIf="popupOpen && !isTrial">
  <button class="btn_close" (click)="closePopup()"><i class="close_btn_icon"></i></button>
  <h2 class="popup_title">Do you have any questions?</h2>
  <p class="popup_text">Do not hesitate to book a call with your concierge, they will help you with any struggles.</p>
  <div class="card_buttons"><button class="confirm" (click)="closePopup"><a href="https://calendar.app.google/r3BgmWNn3XcJcqU6A" target="_blank" rel="noopener noreferrer">Book a call</a></button></div>
</div>

