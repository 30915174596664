import {ActivatedRoute, Router} from '@angular/router';
import {FormGroup, FormControl, Validators} from '@angular/forms';
import {Component, OnInit} from '@angular/core';
import {AuthService} from '../services/auth.service';
import {HttpErrorResponse} from '@angular/common/http';
import {NotificationService} from 'src/app/shared/services/notification.service';
import {firstValueFrom, map, throwError} from "rxjs";
import {catchError} from "rxjs/operators";
import {ResetPasswordState} from './reset-password-state';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  resetPasswordForm: FormGroup;
  private codeId: string;
  private userId: string;

  constructor(private titleService: Title, private _authService: AuthService, private activatedRoute: ActivatedRoute, private router: Router,
              private notificationService: NotificationService, private route: ActivatedRoute) {
    this.resetPasswordForm = new FormGroup({
      'password': new FormControl('', [Validators.required, Validators.minLength(8)]),
      'confirm_password': new FormControl('', [Validators.required, Validators.minLength(8)]),
    });
  }

  async ngOnInit() {
    this.codeId = this.route.snapshot.queryParams['code'];
    this.userId = this.route.snapshot.queryParams['userId'];
    if (!this.userId || !this.codeId) {
      await this.router.navigate(["/not-found"])
      return;
    }

    let response = await firstValueFrom(this._authService.getResetPasswordState(this.userId, this.codeId).pipe(catchError((x) => {
      this.router.navigate(["/not-found"]);
      return throwError(x);
    })));

    if (response.state == ResetPasswordState.Invalid) {
      await this.router.navigate(["/not-found"]);
      return;
    }
    this.titleService.setTitle('Reset password | TEMA');
  }

  async resetPassword(resetPasswordFormValue) {
    if (!this.resetPasswordForm.valid || this.resetPasswordForm.value.password != this.resetPasswordForm.value.confirm_password) {
      this.resetPasswordForm.markAllAsTouched();
      return;
    }
    let response = await firstValueFrom(this._authService.resetPasswordByEmail(this.userId, this.codeId, this.resetPasswordForm.value.password));
    this.notificationService.showInfo("Your password has been updated. Please login to the TEMA Portal.");
    await this.router.navigate(["/login"]);
  }
}
