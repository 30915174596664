import { Component } from '@angular/core';
import {HttpClientService} from "../../../shared/services/http-client.service";

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss']
})
export class NotesComponent {
  public feedbacks: any[] = [];
  public notes: any[] = [];
  public isPersonal: boolean = false;

  constructor(
    private httpClientService: HttpClientService
  ) {
  }

  ngOnInit(): void {
    let url = "api/smrtest/notes";
    this.httpClientService
      .get<any>(url)
      .subscribe((response) => {
        this.notes = response.notes;
      });
  }
}
