import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {HttpClientService} from '../shared/services/http-client.service';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SmrTestFeedbackComponent} from "./test/smr-test-feedback/smr-test-feedback.component";
import {PersonalNotesComponent} from "./test/personal-notes/personal-notes.component";
import {ProgressSmrTestComponent} from "./test/common/progress-smr-test/progress-smr-test.component";
import {SmrTestPageComponent} from "./test/smr-test-page/smr-test-page.component";
import {ScoreComponent} from "./result/score/score.component";
import {FeedbackTabsComponent} from "./result/feedbackTabs/feedbackTabs.component";
import {FeedbackComponent} from "./result/feedback/feedback.component";
import {SmrResultsComponent} from "./result/smrResults.component";
import {SharedModule} from "../shared/shared.module";
import {PopupComponent} from "./result/popup-component/popup.component";
import {ChooseTeamComponent} from './test/choose-team/choose-team.component';
import { NotesComponent } from './result/notes/notes.component';

@NgModule({
  providers: [HttpClientService],
  declarations: [
    SmrTestPageComponent,
    ProgressSmrTestComponent,
    SmrTestFeedbackComponent,
    PersonalNotesComponent,
    SmrResultsComponent,
    ScoreComponent,
    FeedbackTabsComponent,
    FeedbackComponent,
    PopupComponent,
    ChooseTeamComponent,
    NotesComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: 'test',
        component: SmrTestPageComponent,
      },
      {
        path: 'team',
        component: ChooseTeamComponent
      },
      {
        path: ':testId/note',
        component: PersonalNotesComponent
      },
      {
        path: ':testId/feedback',
        component: SmrTestFeedbackComponent
      },
      {
        path: 'result',
        children: [
          {
            path: 'score',
            component: ScoreComponent
          },
          {
            path: 'notes',
            component: NotesComponent
          },
        ],
        component: SmrResultsComponent,
      },
      {
        path: 'result/:teamId',
        children: [
          {
            path: 'score',
            component: ScoreComponent
          },
          {
            path: 'feedback',
            component: FeedbackTabsComponent,
          }
        ],
        component: SmrResultsComponent,
      }
      ]),
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
  ]
})

export class SmrModule {
}
