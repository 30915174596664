import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClientService} from 'src/app/shared/services/http-client.service';
import {ISmrTestGetResultResponse} from '../contracts/smr-test-get-result-response';
import {SmrService} from "../smr.service";
import {TeamService} from "../../../team/services/team.service";
import {firstValueFrom} from "rxjs";
import {TAB} from "@angular/cdk/keycodes";

@Component({
  selector: 'app-smrResults-score',
  templateUrl: './score.component.html',
  styleUrls: ['./score.component.scss'],
})
export class ScoreComponent implements OnInit {
  // data template
  public scoreResult: ISmrTestGetResultResponse;
  public countOfTeams: any;
  public isTeam: any;

  constructor(
    private httpClientService: HttpClientService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public teamService: TeamService,
    public smrService: SmrService
  ) {
  }

  async ngOnInit(){
    this.smrService.scoreResults$.subscribe(x => this.scoreResult = x);
    this.countOfTeams = (await firstValueFrom(this.teamService.getUserTeams())).teams.length;
    this.isTeam = !!this.activatedRoute.parent.snapshot.paramMap.get('teamId');
  }

  getSuggestedLogic(questionType: string) {
    switch (questionType) {
      case 'Happiness':
        return this.isTeam ? 4.7 : 5;
      case 'Trust':
        return this.isTeam ? 4.6 : 5;
      case 'Communication':
        return this.isTeam ? 4.6 : 5;
      case 'Impact':
        return this.isTeam ? 4.8 : 5;
      case 'Empowerment':
        return this.isTeam ? 4.7 : 5;
      case 'Compensation':
        return this.isTeam ? 4.6 : 5;
      case 'Support':
        return this.isTeam ? 4.7 : 4;
      case 'Culture':
        return this.isTeam ? 4.7 : 5;
      case 'Collaboration':
        return this.isTeam ? 4.6 : 5;
      case 'Momentum':
        return this.isTeam ? 4.7 : 5;
      default:
        return 5;
    }
  }

  replaceMethod(text: string) {
    if (text == null || this.scoreResult?.overallScore == 0) {
      return "No data for current period selection.";
    }
    return text;
  }

  heading = 'Overall Score Description';
  content = `This psychometric profile test is designed to assess a person’s cognitive abilities, interests, and personality traits.
  The results of the test can be used to help identify strengths and weaknesses that may be beneficial for career and educational planning.
  In addition to career and educational planning, psychometric profile tests can also be useful for personal development and self-awareness.
  By identifying their cognitive abilities, interests, and personality traits, individuals can gain insight into their own strengths and weaknesses and use this information to make more informed decisions about their personal and professional lives.
  Additionally, the results of the test can also be used to identify potential areas for improvement and development, as well as to set goals and create action plans for achieving those goals.
  However, it's important to note that psychometric profile tests are just one tool among many that can be used for personal and professional development, and should be used in conjunction with other resources and guidance from qualified professionals.`;
  answers = [
    {
      title: 'Communication',
      result: 4.2,
      expected: 4.6,
    },
    {
      title: 'Collaboration',
      result: 4.5,
      expected: 4.7,
    },
    {
      title: 'Support',
      result: 4.8,
      expected: 4.97,
    },
    {
      title: 'Happiness',
      result: 4,
      expected: 4.97,
    },
    {
      title: 'Trust',
      result: 4.3,
      expected: 4.3,
    },
    {
      title: 'Impact',
      result: 4,
      expected: 4.8,
    },
    {
      title: 'Empowerment',
      result: 4.4,
      expected: 4.5,
    },
    {
      title: 'Momentum',
      result: 4.8,
      expected: 4.9,
    },
    {
      title: 'Compensation',
      result: 4.6,
      expected: 4.7,
    },
    {
      title: 'Culture',
      result: 4.4,
      expected: 4.8,
    },
  ];
}

