import {Component, OnInit} from '@angular/core';
import {HttpClientService} from 'src/app/shared/services/http-client.service';
import {ISmrTestCreateRequest} from '../contracts/smrTestCreateRequest';
import {ISmrTestQuestionResponse} from '../contracts/smrTestQuestionRespone';
import {firstValueFrom} from "rxjs";
import {TestService} from "../test.service";
import {Router} from "@angular/router";
import {TeamService} from "../../../team/services/team.service";
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-smr-test-page',
  templateUrl: './smr-test-page.component.html',
  styleUrls: ['./smr-test-page.component.scss'],
})
export class SmrTestPageComponent implements OnInit {
  public listQuestions: any;
  public currentPage: number = 1;
  public errorMessageStatus: boolean = false;

  public userTestCreateRequest: ISmrTestCreateRequest =
    {} as ISmrTestCreateRequest;

  public userAnswerList: any[] = [];
  public testId: any;
  private teams: any[];
  selectedTeam: any;

  constructor(private httpService: HttpClientService, private teamService: TeamService, public testService: TestService, private router: Router, private titleService: Title) {
  }

  getBackgroundColor(questionId, answer) {
    let quesion = this.userAnswerList.find(x => x.questionId == questionId);
    if (!quesion || quesion.answer != answer) {
      return '';
    }
    return '#A5D3FF';

  }

  get currentGroup(): { category: any, questions: any[] } | null {
    if (!this.listQuestions || !this.currentPage) {
      return {category: {}, questions: []};
    }
    return this.listQuestions[this.currentPage] || null;
  }

  async ngOnInit() {
    let canTestResponse = await this.checkCanTestUser();
    if (!canTestResponse.isSmrAvailable) {
      await this.router.navigate(['dashboard'])
    }

    let response = await this.testService.getAvailableTeams();

    this.teams = response.teams;
    if (response.isTeamExist) {
      if (response.teams.length == 0) {
        await this.router.navigate(['dashboard'])
        return;
      }
      if (!this.testService.SelectedTeamId) {
        await this.router.navigate(['smr/team']);
        return;
      }
    }
    if (this.testService.SelectedTeamId) {
      this.selectedTeam = response.teams.filter(x => x.id == this.testService.SelectedTeamId)[0];
    }
    this.userTestCreateRequest.currentUserId = 1;
    this.loadQuestions();
    this.titleService.setTitle('SMR test | TEMA');
  }

  async checkCanTestUser() {
    return await firstValueFrom(this.testService.getSmrStatus());
  }

  public answerAddChecker(impression: number, questionId: number) {
    let answerList = this.userAnswerList.filter(
      (i) => i.questionId === questionId
    );

    if (answerList.length > 0) {
      answerList.forEach((i) => (i.answer = impression));
    } else {
      this.userAnswerList.push({
        questionId: questionId,
        answer: impression,
      });
    }
  }

  loadSMRTestQuestions = async (currentPage: number, callType: string) => {
    this.errorMessageStatus = false;
    if (callType === "back") {
      this.currentPage = this.currentPage - 1;
    }

    if (callType === 'continue') {
      if (this.userAnswerList.length < this.currentPage) {
        this.errorMessageStatus = true;
        return;
      }

      if (this.currentPage == 10) {
        await this.saveAnswers();
        if (this.teams.length == 0 || this.teams.filter(x => x.id == this.testService.SelectedTeamId)[0].countOfMembers == 1) {
          await this.router.navigate([`smr/${this.testId}/note`]);
          return;
        } else {
          await this.router.navigate([`smr/${this.testId}/feedback`]);
          return;
        }
      }
    }

    this.currentPage = currentPage;
  };

  private loadQuestions() {
    this.httpService.get<ISmrTestQuestionResponse>(
      'api/smrTest/questions'
    )
      .subscribe((response) => {
        this.listQuestions = this.groupQuestionsByCategoryId(response.questions);
      });
  }

  private async saveAnswers() {
    let requestObject = {
      userAnswers: this.userAnswerList,
      teamId: this.testService.SelectedTeamId
    }
    let result = await firstValueFrom(this.httpService.post<any>("api/smrtest/answers", requestObject));
    this.teamService.resetTeams();
    this.testId = result.testId;
  }

  groupQuestionsByCategoryId(questions: any[]): { [categoryId: number]: { category: any, questions: any[] } } {
    const grouped: { [categoryId: number]: { category: any, questions: any[] } } = {};

    questions.forEach(question => {
      const categoryId = question.questionCategory.id;

      if (!grouped[categoryId]) {
        grouped[categoryId] = {
          category: {
            id: categoryId,
            title: question.questionCategory.title,
            description: question.questionCategory.description
          },
          questions: []
        };
      }

      grouped[categoryId].questions.push(question);
    });

    return grouped;
  }
}

