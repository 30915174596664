import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {AuthService} from "../../services/auth.service";
import {NotificationService} from "../../../shared/services/notification.service";
import {NotificationStatus} from "../../../shared/models/notification";
import {emailValidator} from "../../../shared/validators/emailValidator";
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-sing-up-email',
  templateUrl: './sing-up-email.component.html',
  styleUrls: ['./sing-up-email.component.scss']
})
export class SingUpEmailComponent implements OnInit {
  signupForm!: FormGroup;

  constructor(private authService: AuthService, private router: Router, private titleService:Title, private notificationService: NotificationService) {
  }

  ngOnInit(): void {
    this.titleService.setTitle("Sign up | TEMA");
    this.signupForm = new FormGroup({
      'email': new FormControl('', [Validators.required, emailValidator]),
      'password': new FormControl('', [Validators.required, Validators.minLength(8)]),
      'confirm_password': new FormControl('', [Validators.required, Validators.minLength(8)]),
      'terms': new FormControl(false, Validators.required)
    })
  }

  submitSignup() {
    const email = this.signupForm.value.email;
    const password = this.signupForm.value.password;
    const confirm = this.signupForm.value.confirm_password;
    if (!this.signupForm.valid || !password === confirm || !this.signupForm.value.terms) {
      this.signupForm.markAllAsTouched();
      return;
    }


    this.authService.signupByEmail(email, password, confirm).subscribe({
      next: (res) => this.router.navigate(['/onboarding']),
      error: (res) => {
        let responseObject = res.error[0];
        this.notificationService.showNotification({Status: NotificationStatus.Warning, Message: responseObject?.Message ?? "Something went wrong"})
      }
    });
  }

  trimValue($event: any) {
    this.signupForm.controls['email'].setValue($event.target.value.trim().toLowerCase());
  }
}
