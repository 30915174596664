<div class="container ">
    <div class="form_wrap set_pass">
      <h2>Change your password</h2>
      <div class="form_container">
        <form [formGroup]="this.resetPasswordForm" (ngSubmit)="resetPassword(resetPasswordForm.value)" id="form">
          <label for="password">Password</label>
          <app-password formControlName="password" id="password"></app-password>
          <div
            *ngIf="this.resetPasswordForm.value.password.length != 0 && this.resetPasswordForm.get('password')?.invalid && this.resetPasswordForm.get('password')?.touched"
            class="error-block">
            <i class="error-icon" aria-hidden="true"></i> 
            <span>Please enter a longer password, it has</span><br>
            <span> to be at least 8 characters long.</span>
          </div>
          <div *ngIf="this.resetPasswordForm.value.password.length == 0 && this.resetPasswordForm.get('password')?.touched" class="error-block">
            <i class="error-icon" aria-hidden="true"></i>
            <span>Please enter your password.</span>
          </div>
          <div>
            <label for="confirm_password">Confirm Password</label>
            <app-password formControlName="confirm_password" id="confirm_password" Placeholder="Confirm Password"></app-password>
            <div *ngIf="this.resetPasswordForm.value.confirm_password.length == 0 && this.resetPasswordForm.get('confirm_password')?.touched"
                 class="error-block">
              <i class="error-icon" aria-hidden="true"></i>
              <span>Please confirm your password.</span><br>
            </div>
  
            <div
              *ngIf="this.resetPasswordForm.value.confirm_password.length != 0 && this.resetPasswordForm.value.confirm_password !== this.resetPasswordForm.value.password && this.resetPasswordForm.get('confirm_password')?.touched"
              class="error-block">
              <i class="error-icon" aria-hidden="true"></i>
              <span>Those passwords didn’t match,</span><br>
              <span>please try again.</span>
            </div>
          </div>
          <button class="primary" appPreventDoubleSubmit>Change password</button>
        </form>
      </div>
    </div>
  </div>