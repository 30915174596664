<div class="smr_feedback_tabs">
  <ul class="nav" role="tablist">
    <li class="nav-item" role="presentation">
      <button class="nav-link" data-bs-toggle="tab"
              (click)="switchToReceived()" data-bs-target="#received-tab">Received
      </button>
    </li>
    <li class="nav-item" role="presentation"  >
      <button class="nav-link" data-bs-toggle="tab"
              (click)="switchToGiven()" data-bs-target="#given-tab">Given
      </button>
    </li>
  </ul>
</div>
<div class="tab-content" id="feedbackTabContent">
  <app-feedback-cards [feedbacks]="givenFeedbacks"  nameOfFeedback="Given" *ngIf="isGiven"></app-feedback-cards>
  <app-feedback-cards [feedbacks]="receivedFeedbacks" nameOfFeedback="Received" *ngIf="!isGiven"></app-feedback-cards>
</div>
