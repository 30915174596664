<div class="backdrop" *ngIf="!closePopup">
  <div class="popup_window">
    <div class="wrap">
      <button class="popup_close" (click)="handleClosePopup()"><i class="close"></i></button>
      <h4 class="popup_heading">{{heading}}</h4>
      <p [innerHTML]="replaceMethod(testResultDescription)" class="popup_text"></p>
      <br>
      <p class="popup_text">
        {{metricsMessage}}
      </p>
      <br>
      <p class="popup_text">
        Visit our <a target="_blank"
                     href="https://www.notion.so/temaresources/Happiness-V1-a2bb27597f684a3a88dbace7aa567958?pvs=4#ceb64b4e34564e32ae419705d6f86aee">TEMA
        HandBook</a> to view options on how to improve or/and maintain your scores as well as to find something new to learn. Keep watching these
        areas to fit and maintain the high level.

        If you need any assistance feel very welcome to schedule your consultation with our TEMA Consultant, to develop your strategy to achieve
        better results.
      </p>
      <br>
      <p></p>
    </div>
  </div>
</div>
<div class="buttons">
  <button type="button" (click)="goToLink('https://www.notion.so/temaresources/Success-Metrics-Rating-SMR-72a2ca43645f4487a2dd2d862ffa21bc?pvs=4#5b884de1428b413c9f81ccfa93b13a27')"  class="btn_primary">TEMA HandBook</button>
  <button type="button"  (click)="goToLink('https://www.temaresources.app/sessionpurchase')"  class="btn_primary">Book a call</button>
</div>
