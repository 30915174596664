import {Router} from '@angular/router';
import {Component, Input, Output, EventEmitter} from '@angular/core';
import {TestService} from "../../../smr/test/test.service";

@Component({
  selector: 'take-test-modal',
  templateUrl: './take-test-modal.html',
  styleUrls: ['./take-test-modal.scss']
})

export class TakeSmrTestModalComponent {
  @Input() openModal: boolean;
  @Output() close = new EventEmitter();

  constructor(private router: Router, private testService: TestService) {
  };

  handleClose() {
    this.close.emit()
  }

  async handleRedirect() {
    let teamResponse = await this.testService.getAvailableTeams();
    if (teamResponse.isTeamExist) {
      await this.router.navigate(['smr/team']);
    } else {
      await this.router.navigate(['smr/test']);
    }

    this.close.emit()
  }
}
