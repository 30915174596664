import {Component} from '@angular/core';
import {NotificationService} from "../../services/notification.service";
import {Notification, NotificationStatus} from "../../models/notification";
import {NavigationStart, Router} from "@angular/router";
import {filter} from "rxjs/operators";

@Component({
  selector: 'app-notification-component',
  templateUrl: './notification-component.component.html',
  styleUrls: ['./notification-component.component.scss']
})
export class NotificationComponent {
  public hidePopup: boolean = true;
  public NotificationStatus = NotificationStatus;
  notification!: Notification;

  constructor(notificationService: NotificationService, router: Router) {
    notificationService.applicationNotification.subscribe(notification => this.handleNewNotification(notification))
  }

  public closePopup() {
    this.hidePopup = true;
  }

  timer: any;
  private handleNewNotification(notification: Notification) {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    this.notification = notification;
    this.hidePopup = false;
    if (this.timer) {
      clearTimeout(this.timer);
    }

    this.timer = setTimeout(() => {
      this.hidePopup = true;
    }, 5000);
  }
}
